<template>
    <div class="soft-wrap">
        <div class="no-data" v-if="JSON.stringify(articleList) == '{}'" @click="createArticle">
            <div class="data-content">
                <i class="el-icon-plus"></i>
                <div class="show-title">新的创作</div>
            </div>
        </div>
        <div class="soft-item" v-else>
            <div class="item-content">
                <div class="top-content">
                    <img :src="articleList.cover" alt="">
                    <div class="hover-box">
                        <div class="hover-item edit" @click="editArticle">编辑</div>
                        <div class="hover-item delete" @click="delArticle">删除</div>
                    </div>
                </div>
                <div class="bottom-content" >
                    <div class="bottom-title">{{articleList.title}}</div>
                    <div class="bottom-text" v-html="articleList.description"></div>
                    <div class="bottom-info">
                        <div class="info-left">人群：{{articleList.people}}</div>
                        <div class="info-right">
                            <img class="weibo" v-if="articleList.platform == 1"
                                 src="../../../../../assets/images/student/weibo.png">
                            <img class="wx" v-if="articleList.platform == 2"
                                 src="../../../../../assets/images/student/weixin.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {stuOpArticleList, stuOpArticleDel} from '@/utils/apis'

    export default {
        name: "softArticle",
        data() {
            return {
                articleList: {},
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                platform: this.$route.meta.type,
                    crowdList:[
                    {
                        value:0,
                        label:'手机'
                    },
                    {
                        value:1,
                        label:'美搭'
                    },
                     {
                        value:2,
                        label:'型男'
                    },
                     {
                        value:3,
                        label:'数码'
                    },
                     {
                        value:4,
                        label:'美容'
                    },
                     {
                        value:5,
                        label:'母婴'
                    },
                     {
                        value:6,
                        label:'旅行'
                    },
                     {
                        value:7,
                        label:'居家'
                    },
                ],
            }
        },
        created() {
            this.getArticleList();
        },
        methods: {
            getArticleList() {
                this.articleList = {}
                let param = {
                    op_id: this.opId,
                    course_id: this.courseId
                }
                stuOpArticleList(param).then(res => {
                    if (res.data !== null) {
                        this.articleList = res.data

                        for(let i=0;i<this.crowdList.length;i++){
                            if(this.articleList.people == this.crowdList[i].value){
                                this.articleList.people = this.crowdList[i].label
                            }
                        }
                    }
                }).catch(err => { 
                    console.error(err)
                })
            },
            createArticle() {
                if (this.platform == 0) {
                    this.$router.push({
                        // path: '/student/trainCenter/startTraining/createArticle',
                        path: '/student/trainCenter/startTraining/createArticle_two',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                            module_id: this.$route.query.module_id || 0,
                        }
                    })
                } else {
                    this.$router.push({
                        // path: '/student/examCenter/createArticle',
                        path: '/student/trainCenter/startTraining/createArticle_two',
                        query:{ module_id: this.$route.query.module_id || 0 }
                    })
                }
            },
            editArticle() {
                console.log(this.platform)
                if (this.platform == 0) {
                    this.$router.push({
                        // path: '/student/trainCenter/startTraining/createArticle',
                        path: '/student/trainCenter/startTraining/createArticle_two',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                            edit_id: this.articleList.id,
                            module_id: this.$route.query.module_id || 0,
                        }
                    })
                } else {
                    this.$router.push({
                        // path: '/student/examCenter/createArticle',
                        path: '/student/trainCenter/startTraining/createArticle_two',
                        query: {
                            edit_id: this.articleList.id,
                            module_id: this.$route.query.module_id || 0,
                        }
                    })
                }
            },
            delArticle(row) {
                this.$confirm('是否删除', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    stuOpArticleDel({id: this.articleList.id}).then((res) => {
                        this.getArticleList()
                    }).catch((err) => {
                        console.log(err);
                    })
                }).catch(() => {
                    this.$message.error('已取消删除');
                })
            },
            goToDetail() {
                if(this.platform == 0){
                    this.$router.push({
                        path: '/student/trainCenter/startTraining/articleDetail',
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                            article_id: this.articleList.id
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/examCenter/articleDetail',
                        query: {
                            article_id: this.articleList.id
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .soft-wrap {
        margin-top: 18px;
        height: calc(100% - 18px);
        display: flex;
        flex-direction: column;
        padding: 0 20px;

        .soft-item {
            background: #FFFFFF;
            border: 1px solid #E5E5E5;
            border-radius: 4px;
            width: 25%;

            .item-content {
                padding: 5px 6px 18px;

                .top-content {
                    height: 160px;
                    position: relative;

                    &:hover {
                        .hover-box {
                            opacity: 1;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .hover-box {
                        position: absolute;
                        display: flex;
                        top: 6px;
                        right: 10px;
                        opacity: 0;

                        .hover-item {
                            width: 40px;
                            height: 40px;
                            background: #FFFFFF;
                            border: 1px solid #EEEEEE;
                            opacity: 0.85;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;

                            &.edit {
                                color: #2461EF;
                            }

                            &.delete {
                                color: #FF0000;
                                margin-left: 18px;
                            }
                        }
                    }
                }

                .bottom-content {
                    cursor: pointer;
                    margin-top: 12px;

                    .bottom-title {
                        font-size: 18px;
                        color: #333333;
                    }

                    .bottom-text {
                        color: #999999;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;

                        ::v-deep p {
                            img {
                                display: none;
                            }
                        }
                    }

                    .bottom-info {
                        color: #666666;
                        display: flex;
                        justify-content: space-between;

                        .info-right {
                            img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }

            }
        }

        .no-data {
            width: 478px;
            height: 203px;
            background: #E7F6EF;
            border: 1px solid #2DC079;
            border-radius: 4px;
            position: relative;
            margin: 0 auto;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .data-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 30px;
                    color: #2DC079;
                }

                .show-title {
                    margin-top: 20px;
                    font-size: 18px;
                    color: #666666;
                }
            }
        }
    }
</style>